
import { Component, Vue } from "vue-property-decorator";
import { readRoles } from "@/store/admin/getters";
import { dispatchGetRoles } from '@/store/admin/actions'

@Component
export default class AdminRoles extends Vue {
    public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'role',
      sortable: true,
      value: 'role',
      align: 'left',
    },
    {
      text: 'Nombre d\'utilisateurs',
      sortable: true,
      value: 'count',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
    },
  ];

  get roles() {
    return readRoles(this.$store);
  }

  public async mounted() {
    await dispatchGetRoles(this.$store);
    this.roles
  }
}
